import { Alert, Snackbar } from "@mui/material";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import { useState } from "react";
import { useCookies } from "react-cookie";
import {
  ADMIN,
  BALLOT,
  BASE_COIN,
  clock,
  KITTYCOIN_PUBKEY,
  PDA_ASSOCIATED,
  PROGRAM_ID,
  REWARDS,
} from "../../../utils";
import { GiDeliveryDrone } from "react-icons/gi";

export default function Airdrop() {
  let [airdropAmount, setAirdropAmount] = useState(100000);
  let [stakeAmount, setStakeAmount] = useState(0);
  let [balance, setBalance] = useState(0);
  let [confirmingTx, setConfirmingTx] = useState(false);
  let [requestingSignature, setRequestingSignature] = useState(false);
  let [voteCounts, setVoteCounts] = useState([0, 0, 0, 0, 0]);
  const [open, setOpen] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["airdropped", false]);

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function airdrop() {
    const tx = new Transaction();
    setRequestingSignature(true);

    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      publicKey
    ).catch((err) => {
      return;
    });

    let acc = await connection.getAccountInfo(tokenAccount);
    if (!acc) {
      let ix = Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        KITTYCOIN_PUBKEY,
        tokenAccount,
        publicKey,
        publicKey
      );
      tx.add(ix);
    }

    let [pda, bumpSeed] = await PublicKey.findProgramAddress([], PROGRAM_ID);

    let data = [Buffer.from([3])];
    tx.add(
      new TransactionInstruction({
        keys: [
          { pubkey: publicKey, isSigner: true, isWritable: true },
          { pubkey: tokenAccount, isSigner: false, isWritable: true },
          { pubkey: pda, isSigner: false, isWritable: true },
          { pubkey: PDA_ASSOCIATED, isSigner: false, isWritable: true },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: REWARDS, isSigner: false, isWritable: true },
          { pubkey: ADMIN, isSigner: false, isWritable: true },
          { pubkey: SystemProgram.programId, isSigner: false, isWritable: true },
        ],
        programId: PROGRAM_ID,
        data: Buffer.concat([Buffer.from([4]), Buffer.from(BigUint64Array.from([BigInt(airdropAmount * BASE_COIN)]).buffer)]),
      })
    );
    const signature = await sendTransaction(tx, connection).catch((error) => {
      console.log(error);
    });
    setRequestingSignature(false);
    if (signature) {
      setConfirmingTx(true);
      await connection.confirmTransaction(signature, "finalized");
      setConfirmingTx(false);
      setOpen(true);
      setCookie("airdropped", true);
    }
  }

  return (
    !cookies.airdropped && (
      <div className="row">
        <div className="col-lg-6">
          <div className="Voting-blk">
            <div className="stake-content">
              <h3>Airdrop</h3>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="charity-blk">
            <div className="stake-contact-single-blk tr">
              <div className="nice-select-blk" style={{ textAlign: "center", color: "black" }}>
                <GiDeliveryDrone size={50} style={{ margin: "0 0 20px 0" }} />
                <br />
                Solana network token fees apply.
              </div>
            </div>
            {confirmingTx ? (
              clock
            ) : requestingSignature ? (
              <div>Please wait...</div>
            ) : (
              publicKey && (
                <div className="charity-btn">
                  <button type="submit" onClick={() => airdrop()}>
                    Claim Airdrop
                  </button>
                </div>
              )
            )}
          </div>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          style={{ width: "15%" }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            Airdrop Claimed!
          </Alert>
        </Snackbar>
      </div>
    )
  );
}
