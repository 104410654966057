import React from 'react';
import HeroSection from "./Hero-section/hero";
import VideoArea from "./video_area/VideoArea";
import StakeArea from "./stake-area/StakeArea";

const Home = () => {
    return (
        <div>
            <HeroSection/>
            <VideoArea/>
            <StakeArea/>
        </div>
    );
};

export default Home;
