import React from "react";
import { Link } from "react-router-dom";
const OffCanvas = () => {
  return (
    <div>
      <div className="off-canvas-menu-wrap">
        <ul className="off-canvas-menu">
          <li>
            <a href="https://member.kittycointoken.org/register">Free Coins</a>
          </li>
          <li>
            <a href="https://www.kittycoinsol.com/tutorial.html#walletsetup">Wallets </a>
          </li>
          <li>
            <a href="https://diamondpaws.kittycoinsol.com/">Staking </a>
          </li>
          <li>
            <a href="https://member.kittycointoken.org/buyCoins">Buy Coins </a>
          </li>
          <li>
            <a href="https://www.kittycoinsol.com/tutorial.html">Tutorials</a>
          </li>
        </ul>

        <div className="header__mobile__button">
          <a href="https://member.kittycointoken.org/login">Login</a>
          <a className="color-btn" href="https://member.kittycointoken.org/register">Get Started</a>
        </div>

        <div className="header__social__link">
          <Link to={{pathname: "https://twitter.com/KittycoinSOL"}} target="_blank">
            <i className="fab fa-twitter"></i>
          </Link>
          <Link to={{pathname: "https://www.youtube.com/channel/UCRn6xC1Gmr6enUia-mg2rIA"}} target="_blank">
            <i className="fab fa-youtube"></i>
          </Link>
          <Link to={{pathname: "https://facebook.com/KittycoinSOL"}} target="_blank">
            <i className="fab fa-facebook-f"></i>
          </Link>
        </div>
        <div className="cls-off-canvas-menu">
          <i className="fal fa-times"></i>
        </div>
      </div>
      <div className="off-canvas-overlay"></div>
    </div>
  );
};

export default OffCanvas;
