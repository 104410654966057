import { Alert, FormControl, InputLabel, MenuItem, Select, Snackbar, Tooltip } from "@mui/material";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram, Transaction, TransactionInstruction } from "@solana/web3.js";
import React, { useContext, useEffect, useState } from "react";
import { StakingContext } from "../../../Routes/Routes";
import {
  BALLOT,
  BASE_COIN,
  clock,
  getBallotData,
  KITTYCOIN_PUBKEY,
  PDA_ASSOCIATED,
  PROGRAM_ID,
  REWARDS,
} from "../../../utils";
import { purple } from "@mui/material/colors";
import { Doughnut } from "react-chartjs-2";
import { useCookies } from "react-cookie";

export default function Voting() {
  let [selection, setSelection] = useState(-1);
  let [stakeAmount, setStakeAmount] = useState(0);
  let [balance, setBalance] = useState(0);
  let [confirmingTx, setConfirmingTx] = useState(false);
  let [requestingSignature, setRequestingSignature] = useState(false);
  let [voteCounts, setVoteCounts] = useState([0, 0, 0, 0, 0]);
  const [open, setOpen] = useState(false);
  const context = useContext(StakingContext);

  const [cookies, setCookie, removeCookie] = useCookies(['voted', false]);

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function getBallot() {
    let ballotAccount = await connection.getAccountInfo(BALLOT);

    if (ballotAccount) {
      let data = await getBallotData(connection, ballotAccount, BALLOT);
      let votes = [];
      for (let i = 0; i < 5; i++) {
        let value = 0;
        for (let j = 4; j >= 0; j--) {
          value = (value << 8) | data.votes[i * 4 + j];
        }
        votes[i] = value;
      }
      setVoteCounts(votes);
    }
  }

  useEffect(() => {
    getBallot();
    const interval = setInterval(() => getBallot(), 20000);
    return clearInterval(() => interval);
  }, []);

  async function vote() {
    let myPublicKey = new PublicKey(publicKey.toString());
    setRequestingSignature(true);

    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      myPublicKey
    ).catch((err) => {
      return;
    });

    let data = [Buffer.from([3])];
    const tx = new Transaction();
    tx.add(
      new TransactionInstruction({
        keys: [
          { pubkey: myPublicKey, isSigner: true, isWritable: false },
          { pubkey: tokenAccount, isSigner: false, isWritable: true },
          { pubkey: BALLOT, isSigner: false, isWritable: true },
          { pubkey: PDA_ASSOCIATED, isSigner: false, isWritable: true },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: REWARDS, isSigner: false, isWritable: true },
        ],
        programId: PROGRAM_ID,
        data: Buffer.concat([Buffer.from([3, selection])]),
      })
    );
    console.log(tx);
    const signature = await sendTransaction(tx, connection).catch((error) => {
      console.log(error);
    });
    setRequestingSignature(false);
    if (signature) {
      setConfirmingTx(true);
      await connection.confirmTransaction(signature, "finalized");
      setConfirmingTx(false);
      setOpen(true);
      setCookie("voted", true);
    }
  }


  const data2 = [
    {
      name: "Organization",
      m: 4000,
      gh: 3000,
      cw: 1320,
      fof: 2482,
      sens: 4999,
    },
  ];

  const data = {
    labels: [
      "Marsh Childrens Home & Orphanage",
      "Gentle Hands Orphanage",
      "CharityWater.org",
      "Filipino Orphanage Foundation.org",
      "SENS.org",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [voteCounts[0], voteCounts[1], voteCounts[2], voteCounts[3], voteCounts[4]],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="row mrb">
      <div className="col-lg-6">
        <div className="Voting-blk">
          <div className="stake-content">
            <h3>Charity Voting</h3>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="charity-blk">
          <div className="stake-contact-single-blk tr">
            <div className="nice-select-blk">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Candidates</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selection}
                  label="Candidates"
                  onChange={(event) => setSelection(event.target.value)}
                >
                  <MenuItem value={0}>Marsh Childrens Home & Orphanage</MenuItem>
                  <MenuItem value={1}>Gentle Hands Orphanage</MenuItem>
                  <MenuItem value={2}>CharityWater.org</MenuItem>
                  <MenuItem value={3}>Filipino Orphanage Foundation.org</MenuItem>
                  <MenuItem value={4}>SENS.org</MenuItem>
                </Select>
              </FormControl>
            </div>
            {
              <div style={{ margin: "50px 0 30px 0" }}>
                <Doughnut data={data} />
              </div>
            }
          </div>
          {confirmingTx ? (
            clock
          ) : requestingSignature ? (
            <div>Please wait...</div>
          ) : (
            publicKey && selection >= 0 && !cookies.voted && (
              <div className="charity-btn">
                <button type="submit" onClick={() => vote()} disabled={selection < 0}>
                  Vote Now
                </button>
              </div>
            )
          )}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ width: "15%" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Vote Successful!
        </Alert>
      </Snackbar>
    </div>
  );
}
