import React, { useContext, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Divider, Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { BiWalletAlt } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaCat } from "react-icons/fa";
import { FiBarChart } from "react-icons/fi";
import { Paper, Snackbar, Stack, styled } from "@mui/material";
import { purple } from "@mui/material/colors";
import { StakingContext } from "../../../Routes/Routes";
import {
  Alert,
  BASE_COIN,
  clock,
  COINS_FOR_1_INTEREST,
  formatNumber,
  formatTime,
  KITTYCOIN_PUBKEY,
  PDA_ASSOCIATED,
  PROGRAM_ID,
  REWARDS,
  secondsPerDay as SECONDS_PER_DAY,
} from "../../../utils";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction, TransactionInstruction } from "@solana/web3.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import BigNumber from "bignumber.js";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const purpleStyle = {
  color: purple[800],
  "&.Mui-checked": {
    color: purple[600],
  },
};

export default function Rewards() {
  const [value, setValue] = useState(-1);
  const [amount, setAmount] = useState(new BigNumber(0));
  const [length, setLength] = useState(0);
  const [interest, setInterest] = useState(new BigNumber(0));
  const [total, setTotal] = useState(new BigNumber(0));
  let [requestingSignature, setRequestingSignature] = useState(false);
  let [confirmingTx, setConfirmingTx] = useState(false);
  const [open, setOpen] = useState(false);

  const context = useContext(StakingContext);

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const handleChange = (event) => {
    setValue(event.target.value);
    let stake = context.stakeAccounts[event.target.value];
    const amount = stake.amount;
    setAmount(amount);
    const minutes = Math.floor((Date.now() / 1000 - stake.time.toNumber()) / 60);
    setLength(minutes);
    const interest = (amount / COINS_FOR_1_INTEREST) * minutes;
    setInterest(interest);
    setTotal(new BigNumber(amount).plus(new BigNumber(interest)));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function claimStake() {
    let myPublicKey = new PublicKey(publicKey.toString());
    let tx = new Transaction();
    let space = 49;
    setRequestingSignature(true);

    let claimedStakePubkey = context.stakePubkeys[value];

    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      myPublicKey
    );

    let [pda, bumpSeed] = await PublicKey.findProgramAddress([], PROGRAM_ID);

    tx.add(
      new TransactionInstruction({
        keys: [
          { pubkey: myPublicKey, isSigner: true, isWritable: false },
          { pubkey: tokenAccount, isSigner: false, isWritable: true },
          { pubkey: claimedStakePubkey, isSigner: false, isWritable: true },
          { pubkey: pda, isSigner: false, isWritable: false },
          { pubkey: PDA_ASSOCIATED, isSigner: false, isWritable: true },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: REWARDS, isSigner: false, isWritable: true },
        ],
        programId: PROGRAM_ID,
        data: Buffer.concat([Buffer.from([1])]),
      })
    );

    let signature;
    try {
      signature = await sendTransaction(tx, connection);
    } catch (error) {
      setRequestingSignature(false);
      return;
    }

    setRequestingSignature(false);
    if (signature) {
      setConfirmingTx(true);
      await connection.confirmTransaction(signature, "finalized");
      setConfirmingTx(false);
      setOpen(true);
      context.setRend(context.rend + 1);
    }
    context.setRend(context.rend++);
    setValue(-1);
  }

  let l = 1;

  return (
    <div className="row mrb">
      <div className="col-lg-6">
        <div className="stake-content">
          <h3>My Kittycoin Stakes</h3>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="stake-reward-tab-blk" style={{ color: "black" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">My Stakes</FormLabel>
                <RadioGroup
                  aria-label="Stake"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  {context.stakeAccounts.map((x, i) => (
                    <FormControlLabel value={i} control={<Radio sx={purpleStyle} />} label={l++} />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Item>
                  <BiWalletAlt size="2em" style={{ marginBottom: "2px" }} />
                  <br />
                  <body2>Starting Amount</body2>
                  <h5 style={{ marginTop: "10px" }}>
                    {publicKey && value >= 0 ? formatNumber(amount / BASE_COIN) : "-"}
                  </h5>
                </Item>
                <Item>
                  <AiOutlineClockCircle size="2em" style={{ marginBottom: "2px" }} />
                  <br />
                  <body2>Length</body2>
                  <h5 style={{ marginTop: "10px" }}>
                    {publicKey && value >= 0 ? formatTime(length) : "-"}
                  </h5>
                </Item>
                <Item>
                  <FiBarChart size="2em" style={{ marginBottom: "2px" }} />
                  <br />
                  <body2>Interest</body2>
                  <h5 style={{ marginTop: "10px" }}>
                    {publicKey && value >= 0 ? formatNumber(interest / BASE_COIN) : "-"}
                  </h5>
                </Item>
                <Item>
                  <FaCat size="2em" style={{ marginBottom: "2px" }} />
                  <br />
                  <body2>Total</body2>
                  <h5 style={{ marginTop: "10px" }}>
                    {publicKey && value >= 0 ? formatNumber(total / BASE_COIN) : "-"}
                  </h5>
                </Item>
              </Stack>
            </Grid>
          </Grid>
          {confirmingTx ? (
            clock
          ) : requestingSignature ? (
            <div>Please wait...</div>
          ) : (
            publicKey &&
            value >= 0 && (
              <div className="charity-btn">
                <button type="submit" onClick={() => claimStake()} style={{ marginTop: "30px" }}>
                  CLAIM STAKE
                </button>
              </div>
            )
          )}
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ width: "15%" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Stake claimed!
        </Alert>
      </Snackbar>
    </div>
  );
}
