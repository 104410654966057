import React, { useEffect, useState } from "react";
import ImgData from "../../../assets/ImgData";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { BASE_COIN, formatNumber, getRewardsData, REWARDS } from "../../../utils";
import { useConnection } from "@solana/wallet-adapter-react";
import { GiCoins, GiSteak } from "react-icons/gi";
const VideoArea = () => {
  const [rewards, setRewards] = useState();

  const { connection } = useConnection();

  async function getRewards() {
    // Check if the account has already been created
    let stakeAccount = await connection.getAccountInfo(REWARDS);

    if (stakeAccount) {
      let data = await getRewardsData(connection, stakeAccount, REWARDS);
      setRewards(data);
    }
  }

  useEffect(() => {
    getRewards();
    const interval = setInterval(() => getRewards(), 20000);
    return clearInterval(() => interval);
  }, []);

  return (
    <div>
      <div className="video_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="video__box_wrp">
                <div className="video__box">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=VqtBnPEpGa4"
                    width={"100%"}
                    height={"100%"}
                    light={ImgData.vidThumbnail}
                  />
                </div>
                <p>
                  Once you have staked your coins, you can vote to determine which organization the
                  <i> Kittycoin Charity Rewards</i> will support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {rewards && (
        <div
          style={{
            color: "black",
            display: "flex",
            width: "30%",
            textAlign: "center",
            margin: "100px auto 0 auto",
          }}
        >
          <div style={{ flexGrow: "1" }}>
            <GiCoins size="5em" style={{ marginBottom: "2px" }} />
            <br />
            <body2>Total Kittycoin Staked</body2>
            <h5 style={{ marginTop: "10px" }}>{formatNumber(rewards.totalCoinsStaked/BASE_COIN)} KSOL</h5>
          </div>
          <div style={{ flexGrow: "1" }}>
            <GiSteak size="5em" style={{ marginBottom: "2px" }} />
            <br />
            <body2>Total Stake Count</body2>
            <h5 style={{ marginTop: "10px" }}>
              {rewards && rewards.totalStakesCount.toString()} stakes
            </h5>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoArea;
