import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { ConnectionContext, useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  Keypair,
  PublicKey,
  sendAndConfirmTransaction,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { createTheme, Slider, Snackbar, ThemeProvider } from "@material-ui/core";
import {
  formatNumber,
  KITTYCOIN_PUBKEY,
  PROGRAM_ID,
  getStakeData,
  PDA_ASSOCIATED,
  BASE_COIN,
  clock,
  REWARDS,
} from "../../../utils";
import { BiWalletAlt } from "react-icons/bi";
import { FaRegAddressCard, FaWallet } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Alert, Divider } from "@mui/material";
import { StakingContext } from "../../../Routes/Routes";
import BigNumber from "bignumber.js";

const CustomSlider = createTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#C449C2",
        height: 8,
        marginTop: 30,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus,&:hover,&$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        textAlign: "center",
        left: "calc(-100% + 14px)",
        top: -22,
        "& *": {
          background: "transparent",
          color: "#000",
        },
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});

export default function Stake() {
  let [stakeAmount, setStakeAmount] = useState(new BigNumber(1000));
  let [balance, setBalance] = useState(new BigNumber(0));
  let [hasToken, setHasToken] = useState(false);
  let [confirmingTx, setConfirmingTx] = useState(false);
  let [requestingSignature, setRequestingSignature] = useState(false);
  const [open, setOpen] = useState(false);
  const context = useContext(StakingContext);

  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  async function getBalance() {
    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      publicKey
    );
    let b = await connection.getTokenAccountBalance(tokenAccount).catch((err) => {
      setHasToken(false);
    });
    if (!b) return;
    b = b.value.uiAmount;
    setBalance(b);
  }

  async function stake() {
    let myPublicKey = new PublicKey(publicKey.toString());
    let tx = new Transaction();
    let space = 49;
    setRequestingSignature(true);

    let seed;
    let emptyStake;
    for (let i = 0; i < 10; i++) {
      seed = i.toString();
      if (!context.stakeAccounts[i]) {
        emptyStake = context.stakePubkeys[i];
        break;
      }
    }

    if (!emptyStake) return;
    const lamports = await connection.getMinimumBalanceForRentExemption(space);

    tx.add(
      SystemProgram.createAccountWithSeed({
        fromPubkey: myPublicKey,
        basePubkey: myPublicKey,
        seed,
        newAccountPubkey: emptyStake,
        lamports,
        space,
        programId: PROGRAM_ID,
      })
    );

    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      myPublicKey
    ).catch((err) => {
      setHasToken(false);
      return;
    });

    tx.add(
      new TransactionInstruction({
        keys: [
          { pubkey: myPublicKey, isSigner: true, isWritable: false },
          { pubkey: tokenAccount, isSigner: false, isWritable: true },
          { pubkey: emptyStake, isSigner: false, isWritable: true },
          { pubkey: PDA_ASSOCIATED, isSigner: false, isWritable: true },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          { pubkey: REWARDS, isSigner: false, isWritable: true },
        ],
        programId: PROGRAM_ID,
        data: Buffer.concat([
          Buffer.from([0]),
          // eslint-disable-next-line no-undef
          Buffer.from(BigUint64Array.from([BigInt(stakeAmount * BASE_COIN)]).buffer),
        ]),
      })
    );
    const signature = await sendTransaction(tx, connection).catch((error) => {
      console.log(error);
    });
    setRequestingSignature(false);
    if (signature) {
      setConfirmingTx(true);
      await connection.confirmTransaction(signature, "finalized");
      setConfirmingTx(false);
      setOpen(true);
      setBalance(balance - stakeAmount);
      context.setRend(context.rend + 1);
    }
  }

  useEffect(() => {
    if (!publicKey) return;
    getBalance();
  }, [publicKey]);

  const onClick = useCallback(async () => {
    if (!publicKey) return;

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: Keypair.generate().publicKey,
        lamports: 1,
      })
    );

    const signature = await sendTransaction(transaction, connection);

    await connection.confirmTransaction(signature, "processed");
  }, [publicKey, sendTransaction, connection]);

  return (
    <div className="row mrb">
      <div className="col-lg-6">
        <div className="stake-blk">
          <div className="stake-content">
            <h3>
              Create New <br />
              Kittycoin Stake
            </h3>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="stake-site-right">
          <div className="stake-site-box">
            <div>
              <FaRegAddressCard size="3em" style={{ marginRight: "10px", color: "#C449C2" }} />
              <label style={{ color: "black", fontSize: "14px" }}>
                {!publicKey ? "Address" : publicKey.toString()}
              </label>
            </div>
            <div>
              <MdAccountBalance
                size="3em"
                style={{ margin: "20px 10px 20px 0 ", color: "#C449C2" }}
              />
              <label style={{ color: "black" }}>
                {"Balance: "} <b>{formatNumber(balance)}</b>
              </label>
            </div>
            <Divider style={{ margin: "30px 10px 20px 10px" }} />

            {publicKey && balance > 0 && (
              <div className="stake-contact-single-blk tr">
                <ThemeProvider theme={CustomSlider}>
                  <div>
                    <Slider
                      value={stakeAmount}
                      onChange={(e, v) => setStakeAmount(v > 1000 ? v - 1000 : v)}
                      min={1000}
                      max={balance}
                      step={Math.pow(10, Math.floor(Math.log10(balance))) / 10}
                      valueLabelDisplay="on"
                      valueLabelFormat={(v) => formatNumber(v)}
                    />
                  </div>
                </ThemeProvider>
              </div>
            )}
            {confirmingTx ? (
              clock
            ) : requestingSignature ? (
              <div>Please wait...</div>
            ) : (
              publicKey &&
              balance > 0 && (
                <div className="charity-btn">
                  {publicKey && (
                    <button type="submit" onClick={() => stake()}>
                      Stake Now
                    </button>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ width: "15%" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Stake successful!
        </Alert>
      </Snackbar>
    </div>
  );
}
