import Logo from './img/logo.png'
import t1 from './img/t1.png'
import t2 from './img/t2.png'
import imgThum from './img/img-thum.jpg'
import vidThumbnail from './img/maxresdefault.jpg'
import Title from './img/title.png'

const ImgData={
    Logo,
    t1,
    t2,
    imgThum,
    vidThumbnail,
    Title
}
export default ImgData;