import React from "react";
import ImgData from "../../assets/ImgData";
import { Link } from "react-router-dom";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";

require("@solana/wallet-adapter-react-ui/styles.css");

const Wallet = () => {
  return (
    <WalletModalProvider>
      <WalletMultiButton style={{ background: "#B349C3" }} />
    </WalletModalProvider>
  );
};

const Header = () => {
  return (
    <div>
      <div className="header__area">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-4">
              <div className="header__logo">
                <a href="index.html">
                  <img src={ImgData.Title} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-10 col-md-8 col-8">
              <div className="header__side-menu__wrap">
                <div className="header__main__menu">
                  <ul>
                    <li>
                      <a href="https://member.kittycointoken.org/register">Free Coins</a>
                    </li>
                    <li>
                      <a href="https://www.kittycoinsol.com/tutorial.html#walletsetup">Wallets </a>
                    </li>
                    <li>
                      <a href="https://diamondpaws.kittycoinsol.com/">Staking </a>
                    </li>
                    <li>
                      <a href="https://member.kittycointoken.org/buyCoins">Buy Coins </a>
                    </li>
                    <li>
                      <a href="https://www.kittycoinsol.com/tutorial.html">Tutorials</a>
                    </li>
                  </ul>
                </div>
                <div className="header__site-right-button">
                  <div className="toggle-bar">
                    <i className="fal fa-align-right"></i>
                  </div>
                  <Wallet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
