import React from "react";

const Footer = () => {
  return (
    <div>
      <div class="footer__area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6">
              <div class="footer__text">
                <h5>Privacy Policy</h5>
                <p>Copyright al right reserved 2021</p>
              </div>
            </div>
            <div class="col-6">
              <div class="footer__menu">
                <ul>
                  <li>
                    <a href="https://www.kittycoinsol.com/KittycoinWhitepaper.pdf">Whitepaper </a>
                  </li>
                  <li>
                    <a href="#">Legal </a>
                  </li>
                  <li>
                    <a href="#">Source Code</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
