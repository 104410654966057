import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import OffCanvas from "../components/off-canvas/Off-canvas";
import Header from "../components/header/Header";
import Home from "../layouts/Home/Home";
import Footer from "../components/Footer/Footer";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { getStakeData, PROGRAM_ID } from "../utils";

export const StakingContext = createContext();

const Routes = () => {
  let [rend, setRend] = useState(0);
  let [context, setContext] = useState({ stakePubkeys: [], stakeAccounts: [], rend, setRend });

  let { connection } = useConnection();
  let { publicKey, sendTransaction } = useWallet();

  useEffect(() => {
    if (!publicKey) {
      setContext({ stakePubkeys: [], stakeAccounts: [], rend, setRend });
      return;
    }

    async function getStakes() {
      let stakePubkeys = [];
      let stakeAccounts = [];
      for (let i = 0; i < 10; i++) {
        let seed = i.toString();
        let newPubkey = await PublicKey.createWithSeed(publicKey, seed, PROGRAM_ID);
        stakePubkeys[i] = newPubkey;

        // Check if the account has already been created
        let stakeAccount = await connection.getAccountInfo(newPubkey);

        if (stakeAccount) {
          let data = await getStakeData(connection, stakeAccount, newPubkey);
          stakeAccounts[i] = data;
        }
      }

      setContext({ stakePubkeys, stakeAccounts, rend, setRend });
    }

    getStakes();
  }, [publicKey, rend]);

  return (
    <Router>
      <StakingContext.Provider value={context}>
        <OffCanvas />
        <Header />
        <Home />
        <Footer />
      </StakingContext.Provider>
    </Router>
  );
};

export default Routes;
