import { useConnection } from "@solana/wallet-adapter-react";
import React, { useEffect, useState } from "react";
import ImgData from "../../../assets/ImgData";
import { getRewardsData, REWARDS, RewardsAccount } from "../../../utils";

const HeroSection = () => {


  return (
    <div>
      <div className="inner_page_hero_blk staking">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner_hero-wrp">
                <img className="left_t_img" src={ImgData.t1} alt="" />
                <img className="left_t_img rt_img" src={ImgData.t2} alt="" />
                <div className="inner_hero">
                  <h2>Staking & Voting</h2>
                  <p>
                    Stake your Kittycoin Tokens using our DeFi app and earn 24% APR! You can stake
                    and reclaim your coins at any time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
