import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletModalProvider, WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Button } from "@solana/wallet-adapter-react-ui/lib/Button";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { KITTYCOIN_PUBKEY } from "../../../utils";
import Airdrop from "./Airdrop";
import Rewards from "./Rewards";
import Stake from "./Stake";
import Voting from "./Voting";

const StakeArea = () => {
  let { connection } = useConnection();
  let { publicKey, sendTransaction } = useWallet();
  let [balance, setBalance] = useState(new BigNumber(-2));

  const Wallet = () => {
    return (
      <WalletModalProvider>
        <WalletMultiButton style={{ background: "#B349C3" }} />
      </WalletModalProvider>
    );
  };

  async function getBalance() {
    let tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID,
      TOKEN_PROGRAM_ID,
      KITTYCOIN_PUBKEY,
      publicKey
    );
    let b = await connection.getTokenAccountBalance(tokenAccount).catch((err) => {
      setBalance(-1);
    });
    if (b === undefined) return;
    b = b.value.uiAmount;
    setBalance(b);
  }

  useEffect(() => {
    if (!publicKey) return;
    getBalance();
  }, [publicKey]);

  if (balance === -2) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px 0 50px 0",
        }}
      >
        <Wallet />
      </div>
    );
  }
  if (balance === -1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px 0 50px 0",
        }}
      >
        <Button
          style={{ backgroundColor: "purple" }}
          onClick={() => window.open("https://member.kittycointoken.org/buyCoins", "_blank")}
        >
          BUY KITTYCOIN
        </Button>
      </div>
    );
  }
  return (
    <div>
      <div className="stake-area">
        <div className="container">
          <div className="row"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="stake-block">
                <Stake />
                <Rewards />
                <Voting />
                <Airdrop />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeArea;
