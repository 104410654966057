import { PublicKey, sendAndConfirmTransaction, SystemProgram, Transaction } from "@solana/web3.js";
import * as borsh from "borsh";
import React from "react";
import { ClockLoader } from "react-spinners";
import MuiAlert from "@mui/material/Alert";

export const interestRates = [32876, 65753, 98630, 131504, 164384]; //# of 1/8ths of a token earned per day, per full token staked.
export const timeSpans = [183, 365, 1095, 1825, 3650]; //In days
export const secondsPerDay = 60 * 60 * 24;
export const KITTYCOIN_PUBKEY = new PublicKey("CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q");
export const PDA_ASSOCIATED = new PublicKey("2MGvcaaDYukKw6URvatDMdkJweQQ8LxHN45i37DLEz5q");
export const PROGRAM_ID = new PublicKey("6iGR47iidzsd36HNMSdAYakEhQChJmYy4gvEPtRuY8Fu");
export const REWARDS = new PublicKey("DvEMEqPtUWM656r6Pv88vgCGfLnrEca9YUYsmqemGA4q");
export const BALLOT = new PublicKey("5EEtty42MtHN3F3xDL8BZMTvDHiMxzp9c6TPmDJVzWX3");
export const ADMIN = new PublicKey("GdEV2JtHEHQe6pSYFA3mCCeSQnH6TgCJy5FFao6gRsUi");
export const BASE_COIN = 10 ** 6;
export const COINS_FOR_1_INTEREST = 2160000; //Number of coins to stake to earn 1 interest per minute.

export function formatNumber(number) {
  if (number < 1_000_000) return Math.floor(number).toLocaleString("en");
  let tripletsRemoved = 0;
  while (number > 999) {
    number /= 1000;
    tripletsRemoved++;
  }
  number = (+number.toFixed(number < 10 ? 1 : 2)).toLocaleString();
  if (tripletsRemoved === 1) number += " thousand";
  else if (tripletsRemoved === 2) number += " million";
  else if (tripletsRemoved === 3) number += " billion";
  else if (tripletsRemoved === 4) number += " trillion";
  return number;
}

export function formatTime(minutes) {
  if (minutes < 60) return minutes + " minutes";
  else if (minutes > 60 * 24) return Math.floor(minutes/60/24) + (minutes > 60 * 48 ? " days" : " day");

  let minutesRemainder = minutes % 60;
  let hours = Math.floor(minutes/60);
  return hours + " hours and " + minutesRemainder + " minutes";
}

export const clock = (
  <div style={{ textAlign: "center" }}>
    <ClockLoader
      color={"purple"}
      css={`
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: 10px;
      `}
    ></ClockLoader>
    Confirming...
  </div>
);

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function getTotalReward(stakeType, stakeAmount) {
  return Math.floor((timeSpans[stakeType] * interestRates[stakeType] * stakeAmount) / 100000000);
}

export function getCurrentInterest(stakeType, stakeAmount, daysRemaining) {
  let daysElapsed = timeSpans[stakeType] - daysRemaining;
  return Math.floor((daysElapsed * interestRates[stakeType] * stakeAmount) / 100000000);
}

export async function getStakeData(connection, acc, pubkey) {
  const accountInfo = await connection.getAccountInfo(pubkey);
  if (accountInfo === null) {
    throw Error("Account not yet created.");
  }
  return borsh.deserialize(StakeSchema, StakeAccount, accountInfo.data);
}

export async function getBallotData(connection, acc, pubkey) {
  const accountInfo = await connection.getAccountInfo(pubkey);
  if (accountInfo === null) {
    throw Error("Account not yet created.");
  }
  return borsh.deserialize(BallotSchema, BallotAccount, accountInfo.data);
}

export async function getRewardsData(connection, acc, pubkey) {
  const accountInfo = await connection.getAccountInfo(pubkey);
  if (accountInfo === null) {
    throw Error("Account not yet created.");
  }
  return borsh.deserialize(RewardsSchema, RewardsAccount, accountInfo.data);
}

class StakeAccount {
  isInitialized;
  owner;
  amount;
  time;

  constructor(fields) {
    this.isInitialized = fields.isInitialized;
    this.owner = fields.owner;
    this.amount = fields.amount;
    this.time = fields.time;
  }
}

class BallotAccount {
  isInitialized;
  votes;

  constructor(fields) {
    this.isInitialized = fields.isInitialized;
    this.votes = fields.votes;
  }
}

export class RewardsAccount {
  isInitialized;
  amount;
  airdropSupply;
  airdropFee;
  totalCoinsStaked;
  totalStakesCount;

  constructor(fields) {
    this.isInitialized = fields.isInitialized;
    this.amount = fields.amount;
    this.airdropSupply = fields.airdropSupply;
    this.airdropFee = fields.airdropFee;
    this.totalCoinsStaked = fields.totalCoinsStaked;
    this.totalStakesCount = fields.totalStakesCount;
  }
}

const StakeSchema = new Map([
  [
    StakeAccount,
    {
      kind: "struct",
      fields: [
        ["isInitialized", "u8"],
        ["owner", [32]],
        ["amount", "u64"],
        ["time", "u64"],
      ],
    },
  ],
]);

const BallotSchema = new Map([
  [
    BallotAccount,
    {
      kind: "struct",
      fields: [
        ["isInitialized", "u8"],
        ["votes", [20]],
      ],
    },
  ],
]);

const RewardsSchema = new Map([
  [
    RewardsAccount,
    {
      kind: "struct",
      fields: [
        ["isInitialized", "u8"],
        ["amount", "u64"],
        ["airdropSupply", "u64"],
        ["airdropFee", "u64"],
        ["totalCoinsStaked", "u64"],
        ["totalStakesCount", "u32"],
      ],
    },
  ],
]);

